











































import Vue from 'vue';
import LoginCard from '@/components/specific/LoginCard.vue';
import { DomainAuthMapper } from '@/store/modules/domain/auth';

export default Vue.extend({
  name: 'SettingApp',
  components: {
    LoginCard,
  },
  data() {
    return {
      copied: false,
    };
  },
  computed: {
    ...DomainAuthMapper.mapState(['userAttributes']),
  },
  methods: {
    async doCopy() {
      try {
        const text = this.userAttributes.agents ? this.userAttributes.agents[0].installId : '';
        await this.$copyText(text);
        this.copied = true;
      } catch (e) {
        this.$$log.error(e);
      } finally {
        await this.sleep(1000);
        this.copied = false;
      }
    },

    sleep(msec: number): Promise<void> {
      return new Promise((resolve) => setTimeout(resolve, msec));
    },

    toLinkGoogleCalendar() {
      this.$router.push('/signup/calendar');
    },
  },
});
